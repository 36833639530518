<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="9">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col md="3">
                                <v-combobox
                                    label="Department"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    :items="$store.getters['department/departments']"
                                    v-model="selectedDepartment"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['department/loadingDepartments']"
                                    @focus="$store.dispatch('department/getDepartments')"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            <v-col md="3">
                                <v-combobox
                                    label="Designation"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    :items="$store.getters['designation/designations']"
                                    :loading="$store.getters['designation/loadingDesignations']"
                                    @focus="$store.dispatch('designation/getDesignations')"
                                    v-model="selectedDesignation"
                                    item-text="name"
                                    item-value="id"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            <v-col md="3">
                                <v-combobox
                                    label="Shift"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    :items="$store.getters['shift/shifts']"
                                    v-model="selectedShift"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['shift/loadingShifts']"
                                    @focus="$store.dispatch('shift/getShifts')"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            <v-col md="3">
                                <v-btn small color="info" height="25" :elevation="1" @click="getEmployees">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-data-table
                            class="custom-data-table elevation-1"
                            id="reportContent"
                            dense
                            :headers="employeeHeaders"
                            :search="employeeSearch"
                            :loading="$store.getters['employee/loadingEmployees']"
                            :items="$store.getters['employee/employees']"
                            @click:row="showDetails"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 50, 100, 500, -1]
                            }"
                            :items-per-page="100"
                        >
                            <template v-slot:top>
                                <v-toolbar dense color="white" :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Ex-Employee List</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        v-model="employeeSearch"
                                        placeholder="Search Employee"
                                        append-icon="mdi-magnify"
                                        style="max-width:300px;"
                                        class="custom-form"
                                    >
                                    </v-text-field>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-btn
                                        color="primary"
                                        class="white--text"
                                        small
                                        dense
                                        height="25"
                                        @click="all_print"
                                    >
                                        <v-icon left small dense> mdi-printer </v-icon>
                                        Print
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            
            <v-col cols="3">
                <v-card
                    tile
                    max-width="375"
                    class="mx-auto"
                    height="100%"
                >
                    <v-card dark tile flat color="light-blue darken-3">
                        <v-card-text class="text-center py-2">
                            <v-avatar color="blue lighten-2" size="80" class="my-2">
                                <v-img contain :src="$store.state.host+selectedEmployee.image"></v-img>
                            </v-avatar>

                            <h2 class="caption">{{ selectedEmployee.emp_id }}</h2>
                            <h1 class="subtitle-2">{{ selectedEmployee.name }}</h1>

                            <div>
                                <!-- <v-btn v-if="$store.state['user'].role != 'User'" icon color="white" :disabled="selectedEmployee.id == null ? true : false" :to="{name: 'Employee Entry', params: { id: selectedEmployee.id }}">
                                    <v-icon>mdi-account-edit</v-icon>
                                </v-btn> -->
                                <v-btn v-if="$store.state['user'].role == 'Admin'" icon color="white" :disabled="selectedEmployee.id == null ? true : false" @click="delete_dialog = true">
                                    <v-icon>mdi-check-circle</v-icon>
                                </v-btn>
                                <v-btn icon color="white" :disabled="selectedEmployee.id == null ? true : false" @click="employeeViewDialog = true">
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                                <v-btn v-if="(selectedEmployee.delete_active_histories && selectedEmployee.delete_active_histories.length > 0)" icon color="white" :disabled="selectedEmployee.id == null ? true : false" @click="history_dialog = true">
                                    <v-icon>mdi-history</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-list dense>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-account-group</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.department_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-card-account-details</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.designation_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-account-settings</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.employment_type }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-clock</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.shift_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-cellphone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.phone }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-email</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.email }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-map-marker</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.present_address }}</v-list-item-title>
                                <v-list-item-subtitle>{{ selectedEmployee.area_name}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon color="light-blue darken-2">mdi-earth</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ selectedEmployee.country_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="employeeViewDialog"
            max-width="900"
        >
            <v-card tile>
                <v-card-text class="pa-2">
                    <v-container fluid>
                        <employee-details :employee="selectedEmployee"></employee-details>
                    </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" height="30px" @click="print">Print</v-btn>
                    <v-btn color="error" height="30px" @click="employeeViewDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="delete_dialog"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Active Note</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                            label="Note"
                            v-model="delete_note"
                            ></v-text-field>

                            <v-text-field
                            label="Password"
                            v-model="user_password"
                            type="password"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDeleteDialog"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteEmployee(selectedEmployee.id)" 
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="history_dialog"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Active/Delete Histories</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-simple-table dense style="width: 100%">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Sl.</th>
                                        <th>Action Type</th>
                                        <th>Note</th>
                                        <th>Time</th>
                                        <th>Action By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(history, ind) in selectedEmployee.delete_active_histories" :key="ind">
                                        <td>{{ ++ind }}</td>
                                        <td>{{ history.action_type }}</td>
                                        <td>{{ history.note }}</td>
                                        <td>{{ history.created_at }}</td>
                                        <td>{{ history.action_by_name }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="history_dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import employeeDetails from '../../components/EmployeeDetailsComponent';
export default {
    name: 'Employees',

    components: {
        'employee-details': employeeDetails,
    },

    data() {
        return {
            selectedDepartment: null,
            selectedShift: null,
            selectedDesignation: null,
            employeeViewDialog: false,
            history_dialog: false,
            employeeSearch: '',
            employeeHeaders: [
                { text: 'Id', value: 'emp_id' },
                { text: 'Bio Id', value: 'biometric_id' },
                { text: 'Name', value: 'name' },
                { text: 'Department', value: 'department_name' },
                { text: 'Designation', value: 'designation_name' },
                { text: 'Shift', value: 'shift_name' },
                { text: 'Phone', value: 'phone' },
                { text: 'Email', value: 'email' },
            ],

            selectedEmployee: {
                id: null,
                image: '',
                emp_id: '',
                name: 'Select an Employee',
                shift_name: 'Shift' ,
                department_name: 'Department' ,
                designation_name: 'Designation' ,
                employment_type: 'Employment Type',
                present_address: 'Employee\'s Address',
                country_name: 'Country',
                area_name: 'Area',
                phone: '01XXXXXXXXX',
                email: 'Employee\'s Email',
            },
            delete_dialog: false,
            delete_note: '',
            user_password: '',
        }
    },

    created() {
        this.getEmployees();
    },

    methods: {
        closeDeleteDialog(){
            this.delete_dialog = false;
            this.delete_note   = '';
            this.user_password   = '';
        },
        async deleteEmployee(id){
            if(this.delete_note == ''){
                alert('note required');
                return;
            }
            if(this.user_password == ''){
                alert('password required');
                return;
            }
            if (!confirm("Are you sure?")) return;
            let returnObj = await this.$store.dispatch("employee/activeEmployee", {id, active_note: this.delete_note, user_password: this.user_password});
            if(returnObj.isSuccess){
                this.closeDeleteDialog();
                this.getEmployees();
            }
        },
        getEmployees() {
            this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    shiftId: this.selectedShift?.id,
                    departmentId: this.selectedDepartment?.id,
                    designationId: this.selectedDesignation?.id,
                    with_relations : true,
                    deleted_only: true,
                }
            });
        },
        
        showDetails(employee) {
            this.selectedEmployee = employee;
        },

        async print() {
            let printWindow = window.open(`/employee-details/${this.selectedEmployee.id}`, '', `width=${screen.width}, height=${screen.height}`);
            printWindow.focus();
            await new Promise(r => setTimeout(r, 3000));
            printWindow.print();
        },
        async all_print(){
            let title = 'Ex-Employee List';

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            let prtHtml = '';
            for (const node of [...document.querySelectorAll('#reportContent table')]) {
                prtHtml += node.outerHTML;
            }

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-8 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'aritech.png'}">
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    table, th, td {
                        border: 1px solid #dee2e6;
                        border-collapse: collapse;
                        padding: 0 !important;
                        font-size: 11px;
                        
                    }
                    table{
                        width: 100%;
                    }
                    th{
                        text-align: center !important;
                    }
                    td{
                        padding-left: 2px !important;
                    }

                    .v-data-table-header th.sortable {
                        text-align: center !important;
                        background: #4caf7773;
                    }

                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }

                    @media print {
                        table { page-break-after:auto }
                        tr    { page-break-inside:avoid; page-break-after:auto }
                        td    { page-break-inside:avoid; page-break-after:auto }
                        thead { display:table-header-group }
                        tfoot { display:table-footer-group }
                    }
                </style>
            `;


            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    }
}
</script>

<style scoped>
>>>.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td{
	height: 0 !important;
	font-size: 11px;
}

</style>